import { HighlightRed, Huge } from "../../../../utils/span-formats";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function EntireCompany() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    Info your teams need to <HighlightRed>excel</HighlightRed>,
                    right when they need it.
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    <HighlightRed>Knowledge is power.</HighlightRed> Information
                    is liberating. Empower your people with knowledge and give
                    them the freedom to do their best work with necessary
                    information.
                </SolutionLanding.Body>
                <SolutionLanding.Video videoAbsoluteSrc="/content/solutions/entireCompany/entire_company_main_image.mov" />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>19%</Huge> of employees time on average is spent
                    searching for and gathering information
                </SolutionFacts.Fact>
                <SolutionFacts.Fact>
                    <Huge>14%</Huge> of employees time is spent on internal
                    communication and collaboration
                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/entireCompany/entire_company_benefits_1.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>Work better together</Feature.Title>
                    <Feature.Body>
                        In a remote-first world, we work across time zones and
                        both in the office and at home. Shoulder taps are a
                        thing of the past. Context keeps everyone on the same
                        page and provides teams with the resources they need to
                        confidently move forward, regardless of when or where
                        they're working.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/entireCompany/entire_company_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Knowledge that scales</Feature.Title>
                    <Feature.Body>
                        When you're growing fast, documenting and sharing
                        information may not seem as important, but giving your
                        people the tools they need to build something great is
                        crucial. Context frees up your experienced employee's
                        time while also providing trusted and accurate
                        information to your new teammates.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/entireCompany/entire_company_benefits_3.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>Knowledge where you work</Feature.Title>
                    <Feature.Body>
                        Context delivers information your team needs right where
                        they're working. Right time, right place as the saying
                        goes…
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection />
        </>
    );
}
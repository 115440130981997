import { HighlightRed } from "../../../utils/span-formats";
import IntegrationCard from "./integration-card";
import styles from "./integrations.module.css";

export default function Integrations() {
    return (
        <div className="flex-col align-center" style={{margin:"80px auto 100px auto"}}>
            <h1 className={styles.integrationsHeader}>Integrations</h1>
            <p style={{marginBottom: "40px", textAlign: "center", maxWidth: "90%"}}>
                Context makes the apps you already use{" "}
                <HighlightRed>even better</HighlightRed>
            </p>
            <div className={styles.integrationsContainer}>
                <IntegrationCard
                    logo={"/content/logos/slack-logo.png"}
                    title="Slack"
                >
                    <ul>
                        <li>Look up company information right in Slack</li>
                        <li>
                            Smart suggestions with contextual information in
                            conversation
                        </li>
                        <li>Slash commands empower your team with knowledge</li>
                    </ul>
                </IntegrationCard>
                <IntegrationCard
                    logo={"/content/logos/drive-logo.png"}
                    title="Google Drive"
                    comingSoon
                >
                    <ul>
                        <li>
                            Search Drive right from Slack for quicker access to
                            important info. No more digging through folders!
                        </li>
                        <li>Quickly share document links with coworkers</li>
                        <li>
                            Context proactively and discretely suggests useful
                            documents in conversation
                        </li>
                    </ul>
                </IntegrationCard>
                <IntegrationCard
                    logo={"/content/logos/confluence-logo.png"}
                    title="Confluence"
                    comingSoon
                >
                    <div className="italic">
                        We want to build what you need! Become a founding
                        partner today to have your input heard!
                    </div>
                </IntegrationCard>
            </div>
        </div>
    );
}
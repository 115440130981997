import { Link } from 'react-router-dom';

export default function CallToAction(props) {
    return (
        <Link
            to={props.to || "/tryforfree"}
            className={`main-cta ${props.variation}`}
        >
            {props.children || "Get started free"}
        </Link>
    );
}
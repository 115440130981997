import { Link } from 'react-router-dom';
import styles from './solutionSelection.module.css';

export default function SolutionSelection() {
    return (
        <div className={styles.container}>
            <h3 className='bold'>How does Context help my team?</h3>
            <p>Context saves teams time and money everyday by empowering individuals with knowledge effortlessly.</p>
            <div className={styles.solutions}>
                <Link to="/solutions/entire-company">Entire Company</Link>
                <Link to="/solutions/sales">Sales</Link>
                <Link to="/solutions/customer-support">Customer Support</Link>
                <Link to="/solutions/engineering">Software Engineering</Link>
                <Link to="/solutions/marketing">Marketing</Link>
                <Link to="/solutions/hr">People Ops</Link>
            </div>
        </div>
    )
}
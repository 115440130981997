import Footer from "./Footer/footer";
import Nav from "./Nav";
import styles from './layout.module.css';

export default function Website(props) {
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
            This project has been closed and the Context Slack app is no longer available.  Thank you for your interest!
            </div>
            <Nav />
            {props.content}
            <Footer footerCTA={props.footerCTA} />
        </div>
    );
}
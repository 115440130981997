import './try-for-free.css'
import { Link } from "react-router-dom";
import EmailForm from "../Home/Landing/EmailForm";
import { Blue } from '../../../utils/span-formats';

export default function TryForFree() {
    return (
        <div className="flex-col align-center mt-5">
            <div className="card try-for-free">
                <h3>Welcome to the squad ✌️</h3>
                <p>
                    Adding Context to your team's Slack channel takes one click!
                </p>
                <p>
                    <i>A simpler solution to knowledge management awaits...</i>
                </p>
                <EmailForm variation="slack" />
            </div>

            <div className="questions">
                <h4 className="bold">
                    What should I know before I add this to my organization's
                    workspace?
                </h4>

                <ul>
                    <li>
                        The free version of Context really is free - forever. No
                        gimmicks or 'gotchas'.{" "}
                        <Blue>Click "Add to Slack" now</Blue> and start
                        organizing & accessing company knowledge - no credit
                        card required.
                    </li>
                    <li>
                        <Blue>
                            You can start to use Context right now for just your
                            team or department without having to add it for your
                            entire organization.
                        </Blue>{" "}
                        You remain in complete control; Context is only added to
                        the Slack channels where you put it.
                    </li>
                    <li>
                        Your data is just that - it's yours! Security is our
                        highest priority at Context, so your data is well
                        protected. See our{" "}
                        <a target="_blank" href="/privacy_and_security.pdf">
                            Privacy and Security
                        </a>{" "}
                        white papers for more information.
                    </li>
                </ul>

                <br />
                <br />
                <p>
                    <Blue>Still have questions or concerns before adding?</Blue>{" "}
                    We want to hear them from you. Shoot us an email at{" "}
                    <a href="mailto:support@getcontextnow.com">
                        support@getcontextnow.com
                    </a>{" "}
                    (inbox no longer monitored) or <Link to="/book">book a demo</Link> so we can get in
                    touch at your earliest convenience.
                </p>
            </div>
        </div>
    );
}
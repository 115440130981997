import styles from './book.module.css';

export default function Book() {
      
	return (
        <div className={styles.container}>
            <p>This project has been closed and the screenshot below is only to show what the page looked like while the project was live</p>
            <img src="/content/Demo/calendly_screenshot.png" alt="calendly screenshot" />
        </div>
    );
}
import React from 'react'
import { Blue } from '../../../utils/span-formats';
import { Video } from '../asset';
import SolutionSelection from '../Solutions/solution-selection';
import FeatureShowcase, { Feature } from './FeatureShowcase/feature-showcase'
import Landing from './Landing/Landing'
import Problem from './Problem/problem'
import { HighlightRed } from "../../../utils/span-formats";


export default function Home() {
    return (
        <>
            <Landing />
            <Problem />
                <div>
                    <h1 className="text-center mb-5 mt-5 bold">
                        <HighlightRed>Context is changing the way employees <i>find</i> the info they need</HighlightRed>
                    </h1>
                    <hr  style={{
                        backgroundColor: '#000000',
                        height: 3.0,
                        marginLeft: 100,
                        marginRight: 100
                    }}/>
                </div>
            <FeatureShowcase>
                <Feature
                    asset={<Video src="/content/home/showcases/NPS.mp4" />}
                >
                    <Feature.Title>
                        Relieve the burden of your experienced employees.
                    </Feature.Title>
                    <Feature.Body>
                        Let your team's MVPs spend more time doing what they do
                        best and{" "}
                        <i>
                            less time searching for information & answering
                            repeat questions.
                        </i>{" "}
                        Context delivers the <Blue>right information</Blue> to
                        the <Blue>right person</Blue> with the{" "}
                        <Blue>right context, right when you need it.</Blue>
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/home/showcases/grayson-quote.svg"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Better onboarding. Finally.</Feature.Title>
                    <Feature.Body>
                        Consolidate company knowledge & keep everyone in the
                        loop, regardless of where they are or how new they are.{" "}
                        <Blue>
                            Context empowers your new teammates from day one.
                        </Blue>{" "}
                        Give them the knowledge and tools they need to be
                        effective, right out of the gates.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Video src="/content/home/showcases/discovery-call.mp4" />
                    }
                >
                    <Feature.Title>
                        "I'm not sure... maybe go try to talk to someone in HR
                        about that?"
                    </Feature.Title>
                    <Feature.Body>
                        Yeah... we've all been there. Then you spend tons of
                        time bouncing around HR trying to find the right person
                        to talk to about your question. With Context,{" "}
                        <Blue>
                            know who is who and be instantly connected with the
                            subject matter expert.
                        </Blue>
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/home/showcases/remote-work.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>
                        Don't let remote work slow down your team!
                    </Feature.Title>
                    <Feature.Body>
                        <i>
                            Remote work makes for even more expensive shoulder taps
                        </i>
                        . Stay in the know whether you're working from home, in
                        the office, or wherever. Context{" "}
                        <Blue>
                            improves internal communication in a remote-first
                            world
                        </Blue>{" "}
                        & makes information accessible to everyone,{" "}
                        <Blue>right where you already collaborate.</Blue>
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection />
        </>
    );
}
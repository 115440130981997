export function Video ({src, className}) {
    return (
        <video
            autoPlay
            loop
            muted
            playsInline
            className={"feature-img-container " + className}
        >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}
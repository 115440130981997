import { HighlightRed, Huge } from "../../../../utils/span-formats";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function HR() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    Knowledge management <HighlightRed>for the people</HighlightRed>
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    Empower your employees by giving them the resources and knowledge they need. Help new teammates be their best selves at work and more fully engage and contribute, even if it’s their first day.
                </SolutionLanding.Body>
                <SolutionLanding.Video
                    videoAbsoluteSrc={"/content/solutions/hr/hr_ops_main_image.mp4"}
                />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>88%</Huge> of organizations don’t onboard well
                </SolutionFacts.Fact>
                <SolutionFacts.Fact>
                    <Huge>69%</Huge> of employees are more likely to stay with a company with a good onboarding experience for at least 3 years
                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/hr/hr_ops_benefits_1.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Scale your Culture. Onboard quicker in every department
                    </Feature.Title>
                    <Feature.Body>
                        New talent has so much to offer when joining your organization. Get your people caught up to speed and in the loop while freeing up incalculable hours of time to build people. Context isn’t just for ‘the new guy’ - empower all individuals with important company info they can access anytime, not just during their first weeks.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/hr/hr_ops_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Confidence, competence & energized</Feature.Title>
                    <Feature.Body>
                        Nobody likes the dreaded first day of work where you sit there, not really sure what or how to do…well, almost anything useful. Improve employee retention by starting off on the right foot! Give your people the resources and knowledge they need to hit the ground running. Managing company information right capitalizes on the energy new employees bring, energizing your entire organization.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/hr/hr_ops_benefits_3.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        End repeat questions once and for all 
                    </Feature.Title>
                    <Feature.Body>
                        Context is on a mission to eliminate repetitive questions that tear away your experienced employee’s focus from their important work. They are total time-wasters, and we want to end that for you. Give newer employees verified and accurate answers that come straight from your experienced team members. 
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection/>
        </>
    );
}
import { useState } from "react";
import SlackButton from "../../slack/slack-button";

export default function EmailForm(props) {
    const [inputs, setInputs] = useState({});
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const submitCallback = props.variation ? () => {
        alert("This project has been closed and the Context Slack app is no longer available.  Thank you for your interest!")
    } : 
    () => {
        setEmailSubmitted(true);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

		var pattern = /^\S+@\S+\.\S+$/
        if (pattern.test(inputs.email)) {
			submitCallback();
		}
		else {
			setInvalidEmail(true);
            return false;
		}

    };

    const button = props.variation === "slack" ? 
        <SlackButton onClick={handleSubmit}/> :
        <button className="btn-context-gradient" type="submit">
            Submit
        </button>;

    return (
        <>
            {!emailSubmitted && (
                <form className={`${props.className} email-form`} onSubmit={handleSubmit}>
                    {!props.variation && <p className="text-center">
                        Sign up for exclusive beta access!
                    </p>}
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-input"
                            name="email"
                            placeholder="Work email address"
                            value={inputs.email || ""}
                            onChange={handleChange}
                        />
                        {button}
                    </div>
                    {invalidEmail && <p className="text-center error">Please enter a valid email address</p>}
                </form>
            )}

            {emailSubmitted && (
                <p className="text-center">
                    Got it! We'll send you an email at {inputs.email} to set you up with exclusive beta access within the next few weeks!
                </p>
            )}
        </>
    );
}
	
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './layout.module.css'

export default function MyNav() {

	return (
        <Navbar expand="md" className="mt-3">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            className={styles.wordLogo}
                            src="/content/word-logo.svg"
                            alt="Context logo"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <NavDropdown title="Solutions" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/solutions/entire-company">Entire Company</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutions/sales">Sales</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutions/customer-support">Customer Support</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutions/engineering">Engineering</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutions/marketing">Marketing</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutions/hr">People Ops</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/integrations">Integrations</Nav.Link>
                        <Nav.Link as={Link} to="/book">Demo</Nav.Link>
                        <Nav.Link as={Link} to="/tryforfree" className="nav-cta">Get started free</Nav.Link>
                        {/* <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
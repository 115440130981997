import { HighlightRed } from "../../../../utils/span-formats";
import CallToAction from "../../Layout/call-to-action";
import './landing-styles.css';

export default function Landing() {
	return(
		<div className="landing">
			<h1 className="text-center mb-3 mt-5 bold">Organize & access team information <HighlightRed italic={false}>effortlessly</HighlightRed></h1>
			<p className="text-center mb-4" style={{fontSize: "19px"}}>Context empowers teams with knowledge by putting info they need <i>right where they work</i>.</p>

            <CallToAction />
            <p
                className="text-center mb-1"
                style={{
                    fontSize: "13px",
                    color: "#aaaaaa",
                    marginTop: "10px",
                }}
            >
                No credit card required 🎉
                <br />
            </p>

            <video autoPlay loop muted playsInline className="landing-image">
                <source src="/content/home/search-hero.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}
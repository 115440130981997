import styles from './solutionFact.module.css';

export default function SolutionFacts(props) {
    return (
        <div className={styles.container}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <defs>
                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"var(--context-dark-blue)", stopOpacity:1}} />
                    <stop offset="100%" style={{stopColor:"var(--context-light-blue)", stopOpacity:1}} />
                    </linearGradient>
                </defs>
                <path
                    fill="url(#gradient1)"
                    fillOpacity="1"
                    d="M0,64L80,85.3C160,107,320,149,480,160C640,171,800,149,960,165.3C1120,181,1280,235,1360,261.3L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                ></path>
            </svg>
            <div className={styles.factContainer}>
                <div className={styles.factList}>{props.children}</div>
            </div>
        </div>
    );
}

SolutionFacts.Fact = (props) => (
    <div className={styles.fact}>{props.children}</div>
);
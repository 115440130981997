import { HighlightRed, Huge } from "../../../../utils/span-formats";
import { Video } from "../../asset";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function Engineering() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    Documentation you can trust is{" "}
                    <HighlightRed>actually accurate & updated</HighlightRed>
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    Equip your devs with knowledge management that doesn't take
                    them out of their workflow to collaborate more effectively
                    and <HighlightRed>build something great</HighlightRed>
                </SolutionLanding.Body>
                <SolutionLanding.Video
                    videoAbsoluteSrc={"/content/solutions/dev/dev_main_image.mov"}
                />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>61%</Huge> of developers spend only 2 to 4 hours a day building
                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/dev/software_dev_benefits_1.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Capitalize on improved knowledge sharing
                    </Feature.Title>
                    <Feature.Body>
                            Keep everyone in the loop with updated, accurate information you can trust as you build with confidence. Better communication on engineer teams affects the company’s bottom line!
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/dev/software_dev_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Collaborate more effectively with other teams</Feature.Title>
                    <Feature.Body>
                        It’s no secret developers have their own language that can be hard to understand at times. Context breaks down communication barriers and keeps everyone in the loop so that customer facing teams can work faster, better, and more confident. 
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={<Video src="/content/solutions/dev/software_dev_benefits_3.mp4" />}
                >
                    <Feature.Title>
                        Onboard developers quicker
                    </Feature.Title>
                    <Feature.Body>
                            Give new team members the resources they need to hit the ground running with a knowledge base that works where you work. Reduce the number of repetitive questions that slows down your experienced employees. We all work better while in the flow. 🙇 Work smarter, not harder.
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection/>
        </>
    );
}
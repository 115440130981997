import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollToTop from "./utils/ScrollToTop";
import { SolutionsRoutes } from "./custom-routes";
import Pricing from "./components/Website/Pricing/Pricing";
import EmailForm from "./components/Website/Home/Landing/EmailForm";
import TryForFree from "./components/Website/try-for-free/TryForFree";
import Website from "./components/Website/Layout/Website";
import Book from "./components/Website/Book/book";
import Demo from "./components/Website/demo";
import Home from "./components/Website/Home/Home";
import Integrations from "./components/Website/Integrations/Integrations";
import Cookie from "./components/Website/Legal/cookie";
import Privacy from "./components/Website/Legal/privacy";

export default function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/demo" element={<Website content={<Demo />} />} />
                <Route path="/solutions/*" element={<SolutionsRoutes />} />
                <Route path="/integrations" element={<Website content={<Integrations />} />} />
                <Route path="/book" element={<Website content={<Book />} />} />
                <Route path="/pricing" element={<Website content={<Pricing />} />} />
                <Route path="/cookie" element={<Website content={<Cookie />} />} />
                <Route path="/privacy" element={<Website content={<Privacy />} />} />
                <Route path="/tryforfree" element={
                    <Website content={<TryForFree />} footerCTA={<EmailForm variation="slack" className="footer-add-to-slack"/>}/>
                } />
                <Route path="/*" element={<Website content={<Home />} />} />
            </Routes>
        </Router>
    );
}

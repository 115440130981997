import { Route, Routes } from "react-router-dom"
import Home from "./components/Website/Home/Home";
import EntireCompany from "./components/Website/Solutions/EntireCompany/entireCompany";
import HR from "./components/Website/Solutions/HR/hr";
import CustomerSupport from "./components/Website/Solutions/CustomerSupport/customerSupport";
import Engineering from "./components/Website/Solutions/Engineering/engineering";
import Marketing from "./components/Website/Solutions/Marketing/marketing";
import Sales from "./components/Website/Solutions/Sales/sales"
import Website from "./components/Website/Layout/Website"

export function SolutionsRoutes() {
    return (
        <Routes>
            <Route path="sales" element={<Website content={<Sales />} />} />
            <Route path="hr" element={<Website content={<HR />} />} />
            <Route path="engineering" element={<Website content={<Engineering />} />} />
            <Route path="entire-company" element={<Website content={<EntireCompany />} />} />
            <Route path="customer-support" element={<Website content={<CustomerSupport />} />} />
            <Route path="marketing" element={<Website content={<Marketing />} />} />
            <Route path="/*" element={<Website content={<Home />} />} />
        </Routes>
    )
}
import styles from "./integrations.module.css";

export default function IntegrationCard({logo, title, comingSoon, children}) {
    return (
        <div className={styles.integrationCard}>
            <img src={logo} alt={`${title} logo`} />
            <h4 className="bold">{title}</h4>
            <p>{children}</p>
            {comingSoon && <p className={styles.comingSoon}>Coming soon!</p>}
        </div>
    );
}
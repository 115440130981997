import { HighlightRed, Huge } from "../../../../utils/span-formats";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function Sales() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    <HighlightRed>In-line</HighlightRed> knowledge your sales
                    team needs
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    Empower your sales force to delight the customer by
                    delivering necessary data throughout the sales cycle.{" "}
                    <HighlightRed>Knowledge really is power</HighlightRed>
                </SolutionLanding.Body>
                <SolutionLanding.Img
                    imgAbsoluteSrc={"/content/solutions/sales/sales_main_image.png"}
                    noShadow
                />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>50%</Huge> of sales reps' time is spent looking for what
                    they need to close deals
                </SolutionFacts.Fact>
                <SolutionFacts.Fact>
                    <Huge>34%</Huge> of sales reps' time is wasted on peer-to-peer
                    interaction that can be avoided (repeat questions while
                    they're in the zone)
                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/sales/sales_benefits_1.png"
                            noShadow
                        />
                    }                >
                    <Feature.Title>
                        Close deals quicker
                    </Feature.Title>
                    <Feature.Body>
                        Eliminate those follow up emails elongating your sales cycle with the information that prospects were hoping for over the phone. Context enables your sales teams with accurate knowledge they need, right where they need it. Did we mention that important info comes to you yet?
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/sales/sales_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Maximize contract value</Feature.Title>
                    <Feature.Body>
                            Distill expertise of your business’ products and services by keeping all reps up to date and in the loop. Context gives your team confidence, and confidence closes bigger deals.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/sales/sales_benefits_3.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Convert more leads
                    </Feature.Title>
                    <Feature.Body>
                            Lead better conversations with information you can trust is accurate and up to date. Give your sales reps more to bring to the table by providing them statistics they can leverage to add value to the prospect’s consideration right in their workflow.
                    </Feature.Body>
                </Feature>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/sales/sales_benefits_4.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Reduce new sales rep onboarding times</Feature.Title>
                    <Feature.Body>
                        Create a culture of knowledge sharing despite your sales team’s growth. Context is a knowledge management platform that scales with you. Provide your newer reps with accurate knowledge that helps them confidently close deals, without accidentally leading prospects astray with out-of-date info.
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection />
        </>
    );
}
import { Video } from "../asset";
import CallToAction from "../Layout/call-to-action";
import styles from './solutionlanding.module.css';

export default function SolutionLanding(props) {
    return (
        <div className={styles.container}>
            <div className={styles.landing}>
                {props.children[0]}
                {props.children[1]}
                <CallToAction />
                <p
                    className="text-center mb-1"
                    style={{
                        fontSize: "13px",
                        color: "#aaaaaa",
                        marginTop: "10px",
                    }}
                >
                    No credit card required 🎉
                </p>
            </div>
            <div className={styles.landingImgContainer}>
                {props.children[2]}
            </div>
        </div>
    );
}

SolutionLanding.Title = (props) => <h1 className="text-center mb-3 mt-5 bold">{props.children}</h1>
SolutionLanding.Body = (props) => <p className="text-center mb-4">{props.children}</p>
SolutionLanding.Video = ({videoAbsoluteSrc}) => (
    <Video src={videoAbsoluteSrc} className={styles.landingImg}/>
);
SolutionLanding.Img = ({imgAbsoluteSrc, alt, noShadow}) => (
    <img src={imgAbsoluteSrc} alt={alt} className={`${styles.landingImg} ${noShadow ? "no-shadow" : ""}`} />	
)
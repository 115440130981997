export function HighlightRed(props) {
    let bold = props.bold === undefined ? true : props.bold;
    let italic = props.italic === undefined ? true : props.italic;

    return (
        <span
            className={`Blue ${bold ? "bold" : ""} ${
                italic ? "italic" : ""
            }`}
        >
            {props.children}
        </span>
    );
}

export function Blue(props) {
    let bold = props.bold === undefined ? true : props.bold;
    let italic = props.italic === undefined ? true : props.italic;
    return (
        <span
            className={`lightBlue ${bold ? "bold" : ""} ${
                italic ? "italic" : ""
            }`}
        >
            {props.children}
        </span>
    );
}

export function Emphasize(props) {
    return <span className="bold italic">{props.children}</span>;
}

export function Huge(props) {
    return <span className="huge-text">{props.children}</span>;
}
import { HighlightRed, Huge } from "../../../../utils/span-formats";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function CustomerSupport() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    Knowledge Management your customer-facing teams{" "}
                    <HighlightRed>thrive on</HighlightRed>
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    Your customer support teams represent your brand and values.{" "}
                    <HighlightRed>
                        Empower them with knowledge to craft incredible customer
                        experiences.
                    </HighlightRed>
                </SolutionLanding.Body>
                <SolutionLanding.Video
                    videoAbsoluteSrc={"/content/solutions/customerSupport/customer_support_main_image.mov"}
                />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>70%</Huge> or more of happy customers will share their positive
                    experience with friends & family
                </SolutionFacts.Fact>
                <SolutionFacts.Fact>
                    <Huge>67%</Huge> of customers surveyed said they would pay more for better customer service

                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/customerSupport/customer_support_benefits_1.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Respond to customers quicker
                    </Feature.Title>
                    <Feature.Body>
                            In a remote-first world, shoulder taps are even more timely and expensive than before. Context proactively provides information customers are looking for to your reps, giving them more time to provide value and upsell. Make conversations rich with knowledge and shorter and save money.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/customerSupport/customer_support_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Cultivate powerful relationships with customers</Feature.Title>
                    <Feature.Body>
                            Customer support teams are the voice of your brand and values. Keep your people sharp with knowledge they need in their workflow so they can spend less time searching for answers and more time shaping positive experiences your customers will rave about.
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/customerSupport/customer_support_benefits_3.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Onboard New Team Members Fast
                    </Feature.Title>
                    <Feature.Body>
                            Context gives your customer facing teams the knowledge they need to do their jobs instantaneously. Reduce gaps in knowledge transfer to keep everyone in the loop. Help newer teammates feel more competent and confident at work, even if it’s their first week. 
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection/>
        </>
    );
}
import './showcase-styles.css';
import CallToAction from '../../Layout/call-to-action';

export default function FeatureShowcase(props) {
    return <div className="showcase-container">{props.children}</div>;
}

export function Feature ({asset, reverse, children}) {
    return (
        <div className={`feature ${reverse ? "wrap" : "wrap-reverse"}`}>
            {reverse && asset}
            <div className="feature-content">
                <div className="feature-text">
                    {children}
                    <div className="feature-cta-container">
                        <CallToAction />
                        <CallToAction to="/book" variation="ghost">
                            Book a demo
                        </CallToAction>
                    </div>
                </div>
            </div>
            {!reverse && asset}
        </div>
    );
};

Feature.Title = (props) => <h3>{props.children}</h3>;
Feature.Body = (props) => <p>{props.children}</p>;
Feature.Img = ({src, noShadow}) => (
    <div className={`feature-img-container ${noShadow ? "no-shadow" : ""}`}>
        <img className="feature-img" src={src} alt="feature showcase" />
    </div>
);
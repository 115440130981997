import CallToAction from "../Layout/call-to-action";

export default function Pricing() {
    return (
        <div className="flex-col align-center mt-5">
            <div className="card">
                <h2>Information  your team needs, <span style={{color:"#55C6D6 "}}>immediately.</span></h2>
                <p>Start absolutely free, no credit card required.</p>

                <CallToAction />

            </div>

            <h2>How many employees will be using Context?</h2>

            { /*

            Refer to Proposed Pricing Doc for all updates on copy and features

            https://docs.google.com/spreadsheets/d/1I3cGCxbBAOUFWXgHuQ3LEFZuJhH7xNd4CdYYHqA84QU/edit#gid=50960170

            */ }

            <h3>Frequently Asked Questions</h3>

            <h5>Could I give this a shot before paying anything?</h5>
                <p>Definitely. Context Pro has a 30 day free trial available with no credit card required! Our Starter version of Context has less functionality but will be free forrever.</p>
            
            <h5>Is there really a difference between Starter and Pro?</h5>
                <p>Yes! Huge difference. Enjoy the benefits of advanced tools and control with Context Pro, including...
                        - No limits to searches your team can perform
                        - Context around <i>any piece of company info</i>, not just acronyms & abbreviations
                        - Dedicated success manager and tailored onboarding call
                        - Your input on requested features and integrations is weighted more heavily</p>
            
            <h5>What happens if the number of employees using Context changes?</h5>
                <p>We will notify you when the number of employees using Context grows or shrinks outside the bounds of your selected plan. After a month, we will automatically switch you to the plan that better suites you and your team.</p>
            
            <h5>Could I pause or cancel?</h5>
                <p>Monthly plans can be canceled at any time without financial penalty. Upon cancellation, you will have continued access to your premium plan until the end of your current billing cycle. If you would like to process a refund, please email us at support@getcontextnow.com.</p>
            
            <h5>Is Context secure?</h5>
                <p>JACOB ANSWER HERE</p>
            
            <h5>Can I import existing company information my company has?</h5>
                <p>Yes, of course. We have an import tool that is part of our onboarding process and makes for adding terminology quick and simple!</p>

        </div>
    )
}
import React from 'react'

export default function Demo() {
    return (
        <>
            <div
                style={{
                    padding: "56.25% 0 0 0",
                    position: "relative",
                    width: "1000px",
                    maxWidth: "90vw",
                    margin: "auto",
                }}
            >
                <iframe
                    src="https://player.vimeo.com/video/664415290?h=6f11afb8cd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                    title="Demo_Featureset_2min"
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    );
}
export default function QuoteCard(props) {
    return (
        <div className="quoteCard">
            <img src={props.profileImgSrc} alt={`${props.name} quote`} className="profileImg" />
            <div className="flex-col quoteContents">
                <p>"{props.children}"</p>
                <p>{props.name}</p>
                <img src={props.logoImgSrc} alt="logo" className="quoteLogo" />
            </div>
        </div>
    );
}
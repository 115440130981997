import { HighlightRed, Huge } from "../../../../utils/span-formats";
import FeatureShowcase, { Feature } from "../../Home/FeatureShowcase/feature-showcase";
import SolutionFacts from "../solution-fact";
import SolutionLanding from "../solution-landing";
import SolutionSelection from "../solution-selection";

export default function Marketing() {
	return (
        <>
            <SolutionLanding>
                <SolutionLanding.Title>
                    Knowledge management marketing teams need to <HighlightRed>reach customers</HighlightRed>
                </SolutionLanding.Title>
                <SolutionLanding.Body>
                    Give your marketing teams the time and resources they need to tell compelling stories 
                    with case studies and testimonials that produce results. <HighlightRed>Help them truly connect to customers.</HighlightRed> 
                </SolutionLanding.Body>
                <SolutionLanding.Img
                    imgAbsoluteSrc={"/content/solutions/marketing/marketing_main_image.png"}
                />
            </SolutionLanding>
            <SolutionFacts>
                <SolutionFacts.Fact>
                    <Huge>90%</Huge> marketers believe their teams aren’t as effective as they could be
                </SolutionFacts.Fact>
                <SolutionFacts.Fact>
                    <Huge>60%</Huge> of marketer’s time is spent searching for information, or responding to constant emails & notifications
                </SolutionFacts.Fact>
            </SolutionFacts>

            <FeatureShowcase>
                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/marketing/marketing_benefits_1.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Align messaging
                    </Feature.Title>
                    <Feature.Body>
                        Effortlessly access the info teams need to drive real results. Ensuring important assets like customer testimonials and case studies are updated and accurate is so easy with Context. Oh, and we’ll bring that info you need to you, right when you need it. Kinda cool, right?
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/marketing/marketing_benefits_2.png"
                            noShadow
                        />
                    }
                    reverse
                >
                    <Feature.Title>Maximize contract value</Feature.Title>
                    <Feature.Body>
                            Distill expertise of your business’ products and services by keeping all moving parts in sync. Nobody is out of the loop - Context gives your team both resources & confidence, which in turn close bigger deals. We like the sound of that.    
                    </Feature.Body>
                </Feature>

                <Feature
                    asset={
                        <Feature.Img
                            src="/content/solutions/marketing/marketing_benefits_3.png"
                            noShadow
                        />
                    }
                >
                    <Feature.Title>
                        Speed up the onboarding process
                    </Feature.Title>
                    <Feature.Body>
                        Create a culture of knowledge sharing despite your team’s growth. Context is a knowledge management platform that scales with you. Empower even the newest team member, working with you or across the organization, with crystal clear messaging. Your employees are your most powerful marketers and speak volumes of your brand and product.
                    </Feature.Body>
                </Feature>
            </FeatureShowcase>

            <SolutionSelection/>
        </>
    );
}
import './footer-styles.css';
import CallToAction from "../call-to-action";
import { Link } from 'react-router-dom';


export default function Footer(props) {
    return (
        <div className="footer">
            <h2 className='bold'>Ready to try Context?</h2>
            <h5>Start for free today and organize company knowledge effortlessly.</h5>
            {props.footerCTA || <CallToAction variation="footer-cta" />}
            <div className='flex-row footer-links'>
                <p className="text-center mb-1" style={{minWidth: "210px"}}>© 2022 Context. All rights reserved.<br /></p>
                <Link to="/privacy" style={{minWidth: "90px"}}>Privacy Policy</Link>
                <Link to="/cookie" style={{minWidth: "90px"}}>Cookie Policy</Link>
            </div>
        </div>
    )
}
import { Blue, Emphasize } from '../../../../utils/span-formats';
import './problem-styles.css';
import QuoteCard from './quote-card';

export default function Problem() {
    const reportLink = <a
    target="_blank"
    rel="noreferrer"
    href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/the-social-economy"
    className="source"
>
    *McKinsey Report
</a>

    return (
        <div className="problem">
            <h2>1.5 HOURS</h2>
            <p style={{ marginBottom: 0 }}>
                are spent <Emphasize>daily</Emphasize> by your average employee
                just <Emphasize>searching</Emphasize> for basic information
                neccessary to do their job ({reportLink})
            </p>

            <p style={{ fontSize: 20, marginTop: "40px" }}>
                Company info is created every day at increasing speeds.
                It is <Emphasize>hard</Emphasize> to keep up,{" "}
                <Emphasize>especially in a remote-first world.</Emphasize>
            </p>

            <div className="quote-container">
                <QuoteCard
                    profileImgSrc="/content/home/amanda.jpg"
                    name="Amanda Crane"
                    logoImgSrc="/content/home/lucid.png"
                >
                    it's completely <Blue>overwhelming</Blue> to sit in a
                    meeting but{" "}
                    <Blue>
                        not understand the context of what is being said
                    </Blue>
                    . It's <Blue>hard to feel empowered and autonomous</Blue> …
                    it can be <Blue>debilitating</Blue>.
                </QuoteCard>

                <QuoteCard
                    profileImgSrc="/content/home/nancy.png"
                    name="Nancy Haight"
                    logoImgSrc="/content/home/weave.png"
                >
                    <Blue>
                        Many are afraid to speak up and suffer in silence
                    </Blue>{" "}
                    or have to dig up their own answers,
                    <Blue> wasting precious time</Blue>. Needless to say, this
                    is not only <Blue>inefficient</Blue>, it is also
                    <Blue> costly</Blue>. There needs to be a better way.
                </QuoteCard>
            </div>
        </div>
    );
}